<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div v-if="hasPermissionToRead">

            <!-- Table Container Card -->
            <b-card
              no-body
              class="mb-0"
            >

              <div class="m-2">

                <!-- Table Top -->
                <b-row>

                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <div>
                      <b-form-group
                        label="Exibir"
                        label-for="idSearchBox"
                        class="mb-0"
                      >
                        <v-select
                          id="idSearchBox"
                          v-model="perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block"
                        >
                          <span slot="no-options">Não há opções para essa quantidade.</span>
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-input
                      v-model.lazy="searchQuery"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block mt-2 ml-2"
                    />
                  </b-col>
                </b-row>
              </div>

              <b-table
                id="users_table"
                class="position-relative"
                primary-key="uuid"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há usuários listados."
                :items="users"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                @row-clicked="onRowSelected"
              >
                <!-- Column: Name -->
                <template #cell(field_name)="data">
                  <b-media>
                    <template #aside>
                      <b-avatar
                        :src="userImages[data.item.uuid]"
                        size="2.5rem"
                      />
                    </template>
                    <div
                      class="font-weight-bold text-nowrap biddingOwnerField mt-50"
                    >
                      {{ data.item.profile.get_full_name }}
                    </div>
                  </b-media>
                </template>

                <!-- Column: Email -->
                <template #cell(field_email)="data">
                  <b-media vertical-align="center">
                    {{ data.item.email }}
                  </b-media>
                </template>

                <!-- Column: Phone  -->
                <template #cell(field_phone)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">
                      {{ data.item.profile.phone_number | VMask(phoneNumberMask) }}
                    </span>
                  </div>
                </template>

                <!-- Column: CPF  -->
                <template #cell(field_cpf)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{ data.item.profile.cpf }}</span>
                  </div>
                </template>

                <!-- Column: Status -->
                <template #cell(field_status)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveUserStatusVariant(data.item.is_active)}`"
                    class="badgeStyle d-inline-flex align-items-center justify-content-center text-capitalize sizeMin"
                  >
                    {{ resolveUserStatusLabel(data.item.is_active) }}
                  </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(field_actions)="data">
                  <div
                    :class="totalItems > 1 ? '' : 'dropdownfix'"
                  >
                    <b-dropdown
                      id="actions"
                      variant="outline"
                      no-caret
                      :dropup="true"
                      :boundary="'scrollParent'"
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        :to="{ name: 'user-detail', params: { id: data.item.uuid } }"
                      >
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Detalhes</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="!hasPermissionToUpdate"
                        :to="{ name: 'user-edit', params: { id: data.item.uuid } }"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="!hasPermissionToDelete"
                        @click="showConfirmOrCancelDelete(data.item)"
                      >
                        <svg-icon
                          type="mdi"
                          size="16"
                          :path="mdiTrashCanOutline"
                        />
                        <span class="align-middle ml-50">Remover</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="data.item.is_active"
                        :disabled="!hasPermissionToUpdate"
                        @click="showConfirmOrCancelActivate(data.item, false)"
                      >
                        <feather-icon icon="UserXIcon" />
                        <span class="align-middle ml-50">Desativar Usuário</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="!data.item.is_active"
                        :disabled="!hasPermissionToUpdate"
                        @click="showConfirmOrCancelActivate(data.item, true)"
                      >
                        <feather-icon icon="UserCheckIcon" />
                        <span class="align-middle ml-50">Ativar Usuário</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <div>
        <b-button
          id="addUserBtn"
          v-b-tooltip.hover
          class="btn-icon rounded-circle m-3"
          variant="primary"
          size="md"
          :to="{ name: 'user-create'}"
          :disabled="!hasPermissionToWrite"
          title="Adicionar Usuário"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'
import SvgIcon from '@jamescoyle/vue-icon'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import router from '@/router'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'

export default {
  components: {
    BAvatar,
    BCard,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    SvgIcon,
    vSelect,
    SpinLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const perPage = 10
    const totalItems = 0
    const currentPage = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const perPageOptions = [10, 25, 50, 100]

    const tableColumns = [
      { key: 'field_name', label: 'Nome', sortable: false },
      { key: 'field_email', label: 'Email', sortable: false },
      { key: 'field_phone', label: 'Telefone', sortable: false },
      { key: 'field_status', label: 'Situação', sortable: false },
      { key: 'field_actions', label: 'Ações' },
    ]

    const users = []
    const userImages = {}
    const isAddNewUserSidebarActive = false

    const phoneNumberMask = ['(', /\d/, /\d/, ') ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

    const isLoaded = false

    return {
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      tableColumns,
      users,
      userImages,
      isAddNewUserSidebarActive,
      phoneNumberMask,
      isLoaded,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.user.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.user.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.user.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.user.can_delete
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      syslic
        .user
        .fetchUsers({
          p: this.currentPage,
          page_size: this.perPage,
          q: this.searchQuery,
        })
        .then(response => {
          this.totalItems = response.data.count
          const userList = []
          response.data.results.forEach(obj => {
            if (!obj.is_superuser) {
              userList.push(obj)
            }
          })
          this.users = userList
          return this.loadUsersPhotos(this.users)
        })
        .then(() => {
          this.isLoaded = true
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados dos usuários.',
              text: 'Não foi possível ler os dados do usuários do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.isLoaded = true
        })
    },
    loadUsersPhotos(users) {
      const promiseList = []
      users.forEach(user => {
        const promise = new Promise((resolve, reject) => {
          syslic.user.getUserImage(user.uuid)
            .then(response => {
              this.userImages[user.uuid] = response
              resolve()
            })
            .catch(() => {
              reject()
            })
        })

        promiseList.push(promise)
      })

      return Promise.allSettled(promiseList)
    },
    deleteUser(item) {
      syslic
        .user
        .deleteUser(item.uuid)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Usuário removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.users.splice(this.users.indexOf(item), 1)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover usuários.',
              text: 'Não foi possível remover o usuários do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    activateUser(item, active) {
      let title = 'Usuário desativado com sucesso.'

      if (active) {
        title = 'Usuário ativado com sucesso.'
      }

      syslic
        .user
        .activateUser(item.uuid, active)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover usuários.',
              text: 'Não foi possível remover o usuários do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o usuário.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteUser(item)
          }
        })
    },
    showConfirmOrCancelActivate(item, active) {
      let title = 'Por favor, confirme que você quer desativar o usuário.'

      if (active) {
        title = 'Por favor, confirme que você quer ativar o usuário.'
      }
      this.$bvModal
        .msgBoxConfirm(title, {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.activateUser(item, active)
          }
        })
    },
    resolveUserStatusVariant(status) {
      if (status === true) return 'success'
      if (status === false) return 'secondary'
      return 'success'
    },
    resolveUserStatusLabel(status) {
      if (status === true) return 'Ativo'
      if (status === false) return 'Inativo'
      return 'Ativo'
    },
    onRowSelected(item) {
      router.push({
        name: 'user-detail',
        params: {
          id: item.uuid,
        },
      })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#addUserBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

#addUserBtn img{
  width: 2.0rem;
  height: 2.0rem;
  filter: invert(100) contrast(100) brightness(1);
}

.sizeMin{
  min-width: 110px
}

.badgeStyle {
  height: 2.2rem;
  min-width: 10rem;
  font-size: 1rem;
}
</style>
